$(document).ready(function () {
	const button = $('.c-header__button .c-btn');

	button.on('click', function (e) {
		e.preventDefault();
		const targetElement = $('[data-js-intelihub-signup]');

		const targetPosition = targetElement.offset().top;
		$('html, body').animate(
			{
				scrollTop: targetPosition,
			},
			1000
		);

		targetElement.find('input[type="email"]').focus();
	});
});
