const swiper = new Swiper('.swiper', {
	// Optional parameters
	loop: true,
	freeMode: true,
	spaceBetween: 0,
	grabCursor: false,
	slidesPerView: 4,
	speed: 2000,
	autoplay: {
		delay: 0,
		disableOnInteraction: false,
	},
});
